<template>
	<div class="">
		<div class="d-flex">
			<div class="px-0 col-2">
				<div class="p-2">
					<input type="text" v-model="busStopFilter" placeholder="Filter" class="form-control">
				</div>
				<div style="overflow-y: scroll; height: 100vh" class="list-group rounded-0">
					<a
						href=""
						@click.prevent="showAllBusStop()"
						:class="activeBusStop == null ? 'active' : ''"
						class="list-group-item"
						>All Bus Stops</a
						>
					<a
						href=""
						:class="
							activeBusStop != null && activeBusStop.id == busStop.id
								? 'active'
								: ''
						"
						@click.prevent="showBusStop(busStop)"
						:key="busStop.id"
						class="list-group-item"
						v-for="busStop in filteredBusStops"
						>
						<div>
							<strong>{{ busStop.location }}</strong>
						</div>
						<span>{{ busStop.location_description }}</span>
					</a>
				</div>
			</div>

			<div class="px-0 col-10">
				<div class="info-windows" style="height: 100vh; width: 100%">
					<google-map
						id="map"
						ref="Map"
						:center="centerPosition"
						:zoom="zoomLevel"
						>
						<google-map-marker
							v-for="infoWindow in infoWindowsList"
							:position="{
								lat: infoWindow.latitude,
								lng: infoWindow.longitude,
							}"
							:key="infoWindow.id"
							:clickable="true"
							@click="toggleInfoWindow(infoWindow)"
							:draggable="true"
							@dragstart="dragStarted(infoWindow, $event)"
							@dragend="dragEnded(infoWindow, $event)"
							/>
						<google-map-infowindow
							v-for="infoWindow in infoWindowsList"
							:position="{
								lat: infoWindow.latitude,
								lng: infoWindow.longitude,
							}"
							:show.sync="infoWindow.showInfo"
							:key="`info-window-${infoWindow.id}`"
							:options="{ maxWidth: 300,pixelOffset:10}"
							@info-window-clicked="infoClicked"
							>
							<div>
								<h4>{{ infoWindow.location }}</h4>
								<p>{{ infoWindow.location_description }}</p>
							</div>
						</google-map-infowindow>
					</google-map>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data () {
    return {
      busStopFilter: '',
      centerPosition: null,
      activeBusStop: null,
      zoomLevel: 15,
      showInfo: true,
      infoWindowContext: {
        position: {
          lat: 44.2899,
          lng: 11.8774
        }
      },
      infoWindowsList: [],
      busStops: []
    }
  },
  mounted () {
    this.loadBusStops()
  },
  computed: {
    filteredBusStops () {
      if (!this.busStopFilter || this.busStopFilter.trim() == '') {
        return this.busStops
      }
      return this.busStops.filter((v) => {
        return new RegExp(this.busStopFilter, 'i').test(v.location)
      })
    }
  },
  methods: {
    panToMarker (pos) {
      this.$refs.Map.panTo(pos)
    },
    async loadBusStops () {
      const result = await this.axios.get('/v1/bus-stops')
      const busStops = result.data
      if (busStops && busStops.length) {
        this.centerPosition = {
          lat: busStops[0].latitude,
          lng: busStops[0].longitude
        }
      }
      const infoWindowsList = busStops.filter((i) => i.latitude && i.longitude)
      infoWindowsList.forEach((i) => {
        i.showInfo = false
      })
      this.infoWindowsList = infoWindowsList
      
      this.busStops = busStops
    },
    toggleInfoWindow (context) {
      const showInfo = context.showInfo || false
      this.$set(context, 'showInfo', !showInfo)
      const contextInfo = {
        position: {
          lat: context.latitude,
          lng: context.longitude
        },
        ...context
      }
      this.infoWindowContext = contextInfo
      this.showInfo = true
    },
    infoClicked (context) {
    },
    showAllBusStop () {
      this.activeBusStop = null
      this.infoWindowsList = [...this.busStops]
      this.zoomLevel = 12
      if (this.infoWindowsList.length) {
        this.panToMarker({
          lat: this.infoWindowsList[0].latitude,
          lng: this.infoWindowsList[0].longitude
        })
      }
    },
    showBusStop (busStop) {
      this.activeBusStop = busStop
      this.infoWindowsList = [busStop]
      this.zoomLevel = 13
      this.panToMarker({
        lat: busStop.latitude,
        lng: busStop.longitude
      })
    },
    dragStarted (busStop, evt) {

    },
    dragEnded (busStop, evt) {
      const lat = evt.latLng.lat()
      const lng = evt.latLng.lng()
      this.handleBusStopLocationUpdate(busStop, lat, lng)
    },
    async handleBusStopLocationUpdate (originalVal, latitude, longitude) {
      const payload = {
        location: originalVal.location,
        location_description: originalVal.location_description,
        latitude,
        longitude
      }
      const id = originalVal.id
      try {
        await this.axios.patch(`/v1/bus-stops/${id}`, payload)
        let _busStopItem = this.busStops.find((i) => i.id == originalVal.id)
        if (_busStopItem) {
          _busStopItem.latitude = latitude
          _busStopItem.longitude = longitude
        }
        _busStopItem = this.infoWindowsList.find((i) => i.id == originalVal.id)
        if (_busStopItem) {
          _busStopItem.latitude = latitude
          _busStopItem.longitude = longitude
        }
        // return window.alert('Busstop updated successfully')
      } catch (e) {
        return window.alert(
          'An error occured while updating busstop, please try again later'
        )
      }
    }
  }
}
</script>
