var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"px-0 col-2"},[_c('div',{staticClass:"p-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.busStopFilter),expression:"busStopFilter"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Filter"},domProps:{"value":(_vm.busStopFilter)},on:{"input":function($event){if($event.target.composing)return;_vm.busStopFilter=$event.target.value}}})]),_c('div',{staticClass:"list-group rounded-0",staticStyle:{"overflow-y":"scroll","height":"100vh"}},[_c('a',{staticClass:"list-group-item",class:_vm.activeBusStop == null ? 'active' : '',attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.showAllBusStop()}}},[_vm._v("All Bus Stops")]),_vm._l((_vm.filteredBusStops),function(busStop){return _c('a',{key:busStop.id,staticClass:"list-group-item",class:_vm.activeBusStop != null && _vm.activeBusStop.id == busStop.id
							? 'active'
							: '',attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.showBusStop(busStop)}}},[_c('div',[_c('strong',[_vm._v(_vm._s(busStop.location))])]),_c('span',[_vm._v(_vm._s(busStop.location_description))])])})],2)]),_c('div',{staticClass:"px-0 col-10"},[_c('div',{staticClass:"info-windows",staticStyle:{"height":"100vh","width":"100%"}},[_c('google-map',{ref:"Map",attrs:{"id":"map","center":_vm.centerPosition,"zoom":_vm.zoomLevel}},[_vm._l((_vm.infoWindowsList),function(infoWindow){return _c('google-map-marker',{key:infoWindow.id,attrs:{"position":{
							lat: infoWindow.latitude,
							lng: infoWindow.longitude,
						},"clickable":true,"draggable":true},on:{"click":function($event){return _vm.toggleInfoWindow(infoWindow)},"dragstart":function($event){return _vm.dragStarted(infoWindow, $event)},"dragend":function($event){return _vm.dragEnded(infoWindow, $event)}}})}),_vm._l((_vm.infoWindowsList),function(infoWindow){return _c('google-map-infowindow',{key:`info-window-${infoWindow.id}`,attrs:{"position":{
							lat: infoWindow.latitude,
							lng: infoWindow.longitude,
						},"show":infoWindow.showInfo,"options":{ maxWidth: 300,pixelOffset:10}},on:{"update:show":function($event){return _vm.$set(infoWindow, "showInfo", $event)},"info-window-clicked":_vm.infoClicked}},[_c('div',[_c('h4',[_vm._v(_vm._s(infoWindow.location))]),_c('p',[_vm._v(_vm._s(infoWindow.location_description))])])])})],2)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }